<!-- System: STA
    Purpose: This compoment will display
            a tooltip , time worked , progress,
            mouse click and keystrokes etc
-->
<template>
  <div class="d-grid d-sm-flex">
    <div class="col-lg-3 col-sm-6 col-12 d-md-flex d-grid">
      <v-card class="main_div" width="300" height="80" elevation="10">
        <div class="mt-3">
          <img src="@/assets/images/schedule icon.svg" class="mr-2" />
          <span> {{ time + ":00" }} to {{ parseInt(time) + 1 + ":00" }} </span>
          <br />
          <span class="ml-7">Time Worked:</span>&nbsp;
          <span style="font-weight: 500"
            >{{ compareTimeWithScreenshots }} mins</span
          >
        </div>
      </v-card>
    </div>

    <v-card
      class="mt-3 col-lg-9 col-sm-6 col-12 main_div d-md-flex d-grid"
      height="80%"
      elevation="10"
      v-if="
        selectedCompanyUserStatus !== 'employee' &&
        (mouseClicks > 0 || keyStrokes > 0)
      "
    >
      <div class="col-md-12 col-12 pa-3 my-auto">
        <div class="d-flex bar">
          <div class="inline-space">
            <img class="clock-icon" src="../../assets/images/Progress1.svg" />
            <span class="heading">Progress: {{ progress }}%</span>
          </div>
          <div class="inline-space">
            <img class="clock-icon" src="../../assets/images/Mouse1.svg" />
            <span class="heading">Mouse Clicks: {{ mouseClicks }}</span>
          </div>
          <div class="inline-space">
            <img class="clock-icon" src="../../assets/images/Keyboard1.svg" />
            <span class="heading">Key Strokes: {{ keyStrokes }}</span>
            <small class="heading"></small>
          </div>
          <div class="inline-space">
            <img class="clock-icon" src="../../assets/images/Idle1.svg" />
            <span class="heading">Idle: {{ idle }}%</span>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TimeSideBar",
  props: [
    "keyStrokes",
    "mouseClicks",
    "secondsWorked",
    "time",
    "workedTime",
    "ScreenStats",
    "lengthOfScreenshot",
  ],
  computed: {
    ...mapState("custom", ["selected_company", "companies_list"]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    progress() {
      return Math.min(
        100,
        this.workedTime
          ? Math.round(
              (this.secondsWorked / (this.workedTime * 60 * 0.33)) * 100
            )
          : 0
      );
    },
    idle() {
      return 100 - this.progress;
    },
    compareTimeWithScreenshots() {
      let imagesCount = Object.keys(this.lengthOfScreenshot).length;
      return imagesCount > this.workedTime ? imagesCount : this.workedTime;
    },
  },
};
</script>

<style scoped>
.main_div {
  background-color: #ffffff !important;
  border: 1px solid #cbdaf2;
  border-radius: 8px;
}

.bar {
  justify-content: space-between;
  flex-wrap: wrap;
}

.heading {
  padding-left: 10px;
  font-size: 18px;
}
</style>
